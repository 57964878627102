import { configureStore, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadPresetsFromFirestore, loadSpotifyTokenFromFirestore, loadSystemsFromFirestore, persistSpotifyTokenToFirestore, persistSystemsToFirestore, System, Systems, Variant } from './firestoreService';
import { wind } from "./root";

export const nonVarFrameKeys = ['barLevels'];

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

interface AppState {
    enabled: boolean;
    activeTab: string;
    guest: boolean;
    particlesEnabled: boolean;
    spotify_token?: string;
    code_strings: any[];
    function_codes: any[];
    slider_names: Record<string, string[]>;
    showBars: boolean;
    isDarkTheme: boolean;
    track: any;
    uid: string | undefined;
    systems: Systems | undefined;
    presets: Systems | undefined;
    currentSystem: System | undefined;
    currentVariant: Variant | undefined;
    currentVariantIndex: number | undefined;
    latexStrings: string[];
    isGradientMode: boolean;
    monitorSize: {width: number, height: number};
}

interface DefaultExpression {
    [key: string]: string;
}

export interface GradientStop {
    id: string;
    color: string;
    position: number;
}

export const defaultExprStrs: DefaultExpression = {
    'h': "1",
    's': "0",
    'v': "0",
    'a': "0",
    'u': "0",
    'p_r': "0",
    'p_g': "0",
    'p_b': "0",
    'h_i': "0",
    'h_a': "1",
    'h_o': "0",
    'h_s': "1",
    'h_l': "0",
    's_i': "0",
    's_a': "1",
    's_o': "0",
    's_s': "1",
    's_l': "0",
    'v_i': "0",
    'v_a': "1",
    'v_o': "0",
    'v_s': "1",
    'v_l': "0",
    'a_i': "0",
    'a_a': "1",
    'a_o': "0",
    'a_s': "1",
    'a_l': "0",
    'u_i': "0",
    'u_a': "1",
    'u_o': "0",
    'u_s': "1",
    'u_l': "0",
    '"e_m(x,l,k)"': "(1-2l)e^(-kx^2)+l",
    '"t_m(x,s,m,n)"': "atan(sx)(m-n)/pi+(m+n)/2",
    'h_m': "t_m(h, h_s, h_a, h_i)",
    's_m': "e_m(s, s_l, s_s)",
    'v_m': "e_m(v, v_l, v_s)",
    'a_m': "e_m(a, a_l, a_s)",
    'u_m': "e_m(a, a_l, a_s)",
    'k': "5",
};

export const defaultLatex = [
    "h=1",
    "s=0",
    "v=0",
    "a=0",
    "u=0",
    "p_r=0",
    "p_g=0",
    "p_b=0",
    "r_m=0",
    "g_m=0",
    "b_m=0",
    "h_i=0",
    "h_a=1",
    "h_o=0",
    "h_s=1",
    "h_l=0",
    "s_i=0",
    "s_a=1",
    "s_o=0",
    "s_s=1",
    "s_l=0",
    "v_i=0",
    "v_a=1",
    "v_o=0",
    "v_s=1",
    "v_l=0",
    "a_i=0",
    "a_a=1",
    "a_o=0",
    "a_s=1",
    "a_l=0",
    "u_i=0",
    "u_a=1",
    "u_o=0",
    "u_s=1",
    "u_l=0",
    "h_m=t_m\\left(h, h_s, h_a, h_i\\right)",
    "s_m=e_m\\left(s, s_l, s_s\\right)",
    "v_m=e_m\\left(v, v_l, v_s\\right)",
    "a_m=e_m\\left(a, a_l, a_s\\right)",
    "u_m=e_m\\left(u, u_l, u_s\\right)",
    "e_m\\left(x, l, k\\right)=(1-2l)e^{-kx^{2}}+l",
    "t_m\\left(x, s, m, n\\right)=\\arctan\\left(sx\\right)\\frac{\\left(m-n\\right)}{\\pi}+\\frac{\\left(m+n\\right)}{2}",
    "k=5",
]
export const initialState: AppState = {
    enabled: true,
    activeTab: 'layers',
    guest: false,
    particlesEnabled: true,
    spotify_token: undefined,
    code_strings: [],
    function_codes: [],
    showBars: false,
    isDarkTheme: true,
    slider_names: {},
    track: {},
    systems: undefined,
    presets: undefined,
    uid: undefined,
    currentSystem: undefined,
    currentVariant: undefined,
    currentVariantIndex: undefined,
    latexStrings: [],
    isGradientMode: false,
    monitorSize: { width: 0, height: 0 },
};

const loadSystemsFromStorage = async (userId: string): Promise<Systems> => {
    try {
        return await loadSystemsFromFirestore(userId);
    } catch (e) {
        console.error("Error loading Systems from Firestore: ", e);
        return {};
    }
};

const loadPresetsFromStorage = async (): Promise<Systems> => {
    try {
        return await loadPresetsFromFirestore();
    } catch (e) {
        console.error("Error loading Systems from Firestore: ", e);
        return {};
    }
};

const persistSystemsToStorage = async (userId: string, systems: Systems) => {
    try {
        await persistSystemsToFirestore(userId, systems);
    } catch (e) {
        console.error("Error persisting Systems to Firestore: ", e);
    }
};

export const loadSpotifyTokenFromStorage = async (userId: string): Promise<any> => {
    try {
        return await loadSpotifyTokenFromFirestore(userId);
    } catch (e) {
        console.error("Error loading Spotify token from Firestore: ", e);
        return {};
    }
};

export async function getAccessToken(code: string): Promise<string> {
    let codeVerifier = wind.localStorage.getItem('spotify_verifier')!;

    const payload = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            //@ts-ignore
            client_id: import.meta.env?.VITE_SPOTIFY_CLIENT_ID ?? wind.env.SPOTIFY_CLIENT_ID,
            grant_type: 'authorization_code',
            code,
            redirect_uri: "http://localhost:5173/callback",
            code_verifier: codeVerifier,
        }),
    }

    const body = await fetch("https://accounts.spotify.com/api/token", payload);
    const response = await body.json();
    return response.access_token;
}

const persistSpotifyTokenToStorage = async (userId: string, tokenInfo: any) => {
    try {
        await persistSpotifyTokenToFirestore(userId, tokenInfo);
    } catch (e) {
        console.error("Error persisting Spotify token to Firestore: ", e);
    }
};

export const loadSystemsAsync = createAsyncThunk(
    'app/loadSystems',
    async (uid: string | null, thunkAPI) => {
        if (uid) {
            return await loadSystemsFromStorage(uid);
        }
    }
);

export const loadPresetsAsync = createAsyncThunk(
    'app/loadPresets',
    async (thunkAPI) => {
        return await loadPresetsFromStorage();
    }
);

const filterNonDefaultLatex = (latexStrings: string[]): string[] => {
    const defaultSet = new Set(defaultLatex);
    return latexStrings.filter(latex => !defaultSet.has(latex));
};

export const loadInitialVariant = createAsyncThunk(
    'app/loadInitialVariant',
    async (systemName: string, { dispatch, getState }) => {
        const state = getState() as RootState;
        const variantIndex = state.app.currentVariantIndex ?? 0;
        dispatch(loadVariantFromIndex({ systemName, index: variantIndex }));
    }
);

function setSystemsFunc(state: AppState, systems: Systems) {
    if (systems && Object.keys(systems).length > 0) {
        const variantIndex = 0;
        state.systems = systems;
        state.currentVariantIndex = variantIndex;
        const systemName = Object.keys(state.systems)[0]!;
        state.currentSystem = state.systems[systemName]!;
    } else {
        state.systems = undefined;
    }
}

function getAllLatexStringsFromCustom(custom: string[]): string[] {
    const latexByLHS: Record<string, string> = {};
    let latexStrings = [...defaultLatex]

    for (let latexString of custom) {
        const [lhs, rhs] = latexString.split('=');
        latexByLHS[lhs!] = rhs ?? '';
    }

    for (let lhs in latexByLHS) {
        const index = latexStrings.findIndex(str => str.startsWith(lhs + '='));
        if (index !== -1) {
            latexStrings[index] = lhs + '=' + latexByLHS[lhs]!;
        } else {
            latexStrings.push(lhs + '=' + latexByLHS[lhs]!);
        }
    }

    return latexStrings
}

function latexStringsNotInCustom(custom: string[]): string[] {
    const customLHS = new Set(custom.map(str => str.split('=')[0]?.trim()));

    return defaultLatex.filter(latexString => {
        const lhs = latexString.split('=')[0]?.trim();
        return !customLHS.has(lhs);
    });
}

function loadVariantFunc(state: AppState, variant: Variant) {
    if (variant) {
        let latexStrings = getAllLatexStringsFromCustom(variant.latexStrings)
        state.latexStrings = latexStrings;
        state.currentVariant = { ...variant, latexStrings };
        wind.internalFloats = { ...variant.internalFloats };
    }
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setGuest(state, action: PayloadAction<boolean>) {
            state.guest = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setEnabled(state, action: PayloadAction<boolean>) {
            state.enabled = action.payload;
        },
        setMonitorSize(state, action: PayloadAction<{ width: number, height: number }>) {
            state.monitorSize = action.payload;
        },
        setParticlesEnabled(state, action: PayloadAction<boolean>) {
            state.particlesEnabled = action.payload;
        },
        setCodeStrings(state, action: PayloadAction<any>) {
            state.code_strings = action.payload;
        },
        setCurrentSystem(state, action: PayloadAction<System | undefined>) {
            state.currentSystem = action.payload;
        },
        setFunctionStrings(state, action: PayloadAction<any>) {
            state.function_codes = action.payload;
        },
        setLatexStringAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            if (action.payload.index < state.latexStrings.length) {
                state.latexStrings[action.payload.index] = action.payload.value;
            } else {
                state.latexStrings.push(action.payload.value);
            }

            state.latexStrings.push(...latexStringsNotInCustom(state.latexStrings));
        },
        setLatexStrings: (state, action: PayloadAction<string[]>) => {
            state.latexStrings = action.payload;
        },
        setSliderNames(state, action: PayloadAction<any>) {
            state.slider_names = action.payload;
        },
        setShowBars(state, action: PayloadAction<boolean>) {
            state.showBars = action.payload;
        },
        setTrack(state, action: PayloadAction<any>) {
            if (state.track !== action.payload) {
                state.track = action.payload;
            }
        },
        toggleDarkTheme(state) {
            state.isDarkTheme = !state.isDarkTheme;
        },
        setVariant(state, action: PayloadAction<{ systemName: string; index: number; name?: string }>) {
            if (state.systems) {
                const { systemName, index, name } = action.payload;

                if (!state.systems[systemName]) {
                    state.systems[systemName] = { name: systemName, variants: [] };
                }

                const filteredLatexStrings = filterNonDefaultLatex(state.latexStrings);

                if (index >= state.systems[systemName].variants.length) {
                    state.systems[systemName].variants.push({
                        name: name || `Variant ${index + 1}`,
                        latexStrings: filteredLatexStrings,
                        internalFloats: { ...wind.internalFloats },
                    });
                } else {
                    state.systems[systemName].variants[index] = {
                        name: name || state.systems[systemName].variants[index]!.name,
                        latexStrings: filteredLatexStrings,
                        internalFloats: { ...wind.internalFloats },
                    };
                }

                if (state.uid) {
                    persistSystemsToStorage(state.uid, state.systems);
                }
            }
        },
        loadVariantFromIndex(state, action: PayloadAction<{ systemName: string, index: number }>) {
            const variant = state.systems?.[action.payload.systemName]?.variants[action.payload.index]!;
            state.currentVariantIndex = action.payload.index;
            loadVariantFunc(state, variant);
        },
        loadVariant(state, action: PayloadAction<Variant>) {
            loadVariantFunc(state, action.payload);
        },
        setSystems(state: any, action: PayloadAction<Systems>) {
            setSystemsFunc(state, action.payload);
        },
        setSpotifyToken(state, action: PayloadAction<any>) {
            state.spotify_token = action.payload;
            if (state.uid) {
                persistSpotifyTokenToStorage(state.uid, action.payload);
            }
        },
        setUser(state, action: PayloadAction<string | undefined>) {
            state.uid = action.payload;
        },
        setIsGradientMode(state, action: PayloadAction<boolean>) {
            state.isGradientMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadSystemsAsync.fulfilled, (state, action: PayloadAction<Systems | undefined>) => {
            if (action.payload) {
                setSystemsFunc(state, action.payload);
                loadVariantFunc(state, Object.values(action.payload)[0]!.variants[0]!);
            }
        });

        builder.addCase(loadPresetsAsync.fulfilled, (state, action: PayloadAction<Systems | undefined>) => {
            if (action.payload && Object.keys(action.payload).length > 0) {
                state.presets = action.payload;
            }
        });
    },
});

export const {
    setActiveTab, setCodeStrings, setEnabled, setFunctionStrings, setGuest, setLatexStringAtIndex,
    setLatexStrings, setParticlesEnabled, setSliderNames, setShowBars, setSpotifyToken,
    setTrack, setUser, setVariant, loadVariant, setSystems, setCurrentSystem,
    toggleDarkTheme, loadVariantFromIndex, setIsGradientMode, setMonitorSize
} = appSlice.actions;

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
    },
});