import { wind } from "./root";

export interface Variant {
    name?: string;
    latexStrings: string[];
    internalFloats: Record<string, number>;
}

export type Systems = Record<string, System> | undefined;

export interface System {
    variants: Variant[];
    name: string;
}

const USERS_COLLECTION = "users";
const GLOBAL_COLLECTION = "global";
const PRESETS_DOC = "presets";
const SYSTEMS_SUBCOLLECTION = "systems";
const USER_SYSTEMS_DOC = "userSystems";

export const setSystemsFirestore = async (userId: string, systems: Systems) => {
    try {
        const userDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId);
        await wind.firebaseFirestoreModule.setDoc(userDocRef, { initialized: true });

        const systemsDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId, SYSTEMS_SUBCOLLECTION, USER_SYSTEMS_DOC);
        await wind.firebaseFirestoreModule.setDoc(systemsDocRef, { systems });
    } catch (e) {
        console.error("Error initializing default systems in firebaseFirestore: ", e);
    }
}

export const createUser = async (uid: string, email: string, firstName: string, lastName: string) => {
    const userDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, "users", uid);
    await wind.firebaseFirestoreModule.setDoc(userDocRef, {
        email,
        firstName,
        lastName,
        createdAt: new Date().toISOString()
    });
};

export const loadSystemsFromFirestore = async (userId: string): Promise<Systems> => {
    const systemsDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId, SYSTEMS_SUBCOLLECTION, USER_SYSTEMS_DOC);
    const systemsDoc = await wind.firebaseFirestoreModule.getDoc(systemsDocRef);
    return systemsDoc.exists() ? systemsDoc.data().systems : {}; 
};

export const loadPresetsFromFirestore = async (): Promise<Systems> => {
    const presetsDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, GLOBAL_COLLECTION, PRESETS_DOC);
    const presetsDoc = await wind.firebaseFirestoreModule.getDoc(presetsDocRef);
    return presetsDoc.exists() ? presetsDoc.data().systems : {}; 
};

export const persistSystemsToFirestore = async (userId: string, systems: Systems) => {
    const systemsDocRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId, SYSTEMS_SUBCOLLECTION, USER_SYSTEMS_DOC);
    await wind.firebaseFirestoreModule.setDoc(systemsDocRef, { systems });
};

export const loadSpotifyTokenFromFirestore = async (userId: string): Promise<any> => {
    const docRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId, "tokens", "spotify");
    const tokenDoc = await wind.firebaseFirestoreModule.getDoc(docRef);
    return tokenDoc.exists() ? tokenDoc.data() : {};
};

export const persistSpotifyTokenToFirestore = async (userId: string, tokenInfo: any) => {
    const docRef = wind.firebaseFirestoreModule.doc(wind.firebaseFirestore, USERS_COLLECTION, userId, "tokens", "spotify");
    await wind.firebaseFirestoreModule.setDoc(docRef, tokenInfo, { merge: true });
};
